import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function Stats(el) {
  const stats = [...el.querySelectorAll("[data-stat]")];
  stats.forEach(animateStat);
}

function animateStat(stat) {
  const reg = /[\d\.]+/;
  const text = stat.innerText;
  const num = +text.match(reg)?.[0];
  if (num) {
    console.log({ text, num });
    let anim = {
      val: 0,
    };
    gsap.to(anim, 2, {
      val: num,
      scrollTrigger: stat,
      onUpdate() {
        const dec = text.includes(".") ? 1 : 0;
        stat.innerText = text.replace(reg, anim.val.toFixed(dec));
      },
      ease: "power2.out",
    });
  }
}
