import barba from "@barba/core";
import "core-js/stable";
import "regenerator-runtime/runtime";
import Headroom from "headroom.js";
import Menu from "./js/Menu";
import DropdownSedimentary from "./js/Dropdown-sedimentary";
import DropdownPegmatite from "./js/Dropdown-pegmatite";
import DropdownBrine from "./js/Dropdown-brine";
import DropdownAbout from "./js/Dropdown-about";
import DropdownInvestors from "./js/Dropdown-investors";

import Links from "./js/Links";

import GMap from "./js/GMap";
import Animations from "./js/Animations";

import Popup from "./js/Popup";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { gsap } from "gsap/dist/gsap";

import lazySizes from "lazysizes";
import Slider from "./js/Slider";
import MediaFilter from "./js/MediaFilter";
import Stock from "./js/Stock";
import Article from "./js/Article";
import Video from "./js/Video";
import Alpine from "alpinejs";
window.Alpine = Alpine;
import Form from "./js/Form";
import Stats from "./js/Stats";
import Table from "./js/Table";
Alpine.start();
import "./app.scss";

let actions = {};
let actionChain = new Map();
function registerAction(action) {
  actions[action.name] = {
    mount: action,
  };
}

barba.hooks.beforeEnter((data) => {});
barba.hooks.after((data) => {
  runActions();
});

function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}

gsap.registerPlugin(ScrollTrigger);
registerAction(Animations);

runActions();

function runActions() {
  console.log("runActions");
  action("Slider", Slider);
  action("MediaFilter", MediaFilter);
  action("Stock", Stock);
  action("Article", Article);
  action("Video", Video);
  action("Form", Form);
  action("Stats", Stats);
  action("GMap", GMap);

  Links(document.querySelectorAll(".links-button"));
  Menu(document.querySelectorAll(".menu-btn"));
  DropdownSedimentary(document.querySelectorAll(".dropdown-btn-sedimentary"));
  DropdownPegmatite(document.querySelectorAll(".dropdown-btn-pegmatite"));
  DropdownBrine(document.querySelectorAll(".dropdown-btn-brine"));
  DropdownAbout(document.querySelectorAll(".dropdown-btn-about"));
  DropdownInvestors(document.querySelectorAll(".dropdown-btn-investors"));

  Popup(document.querySelectorAll(".popup-btn"));
  document.querySelectorAll(".wysiwyg table").forEach(Table);

  const headroomElement = document.querySelector("#main-header");
  const headroom = new Headroom(headroomElement, {
    offset: 200,
  });
  headroom.init();

  const modelBtn = document.querySelectorAll(".model-btn");
  const linksButton = document.querySelectorAll(".links-button");
  const modelClose = document.querySelectorAll(".model-close");

  if (modelClose) {
    modelClose.forEach((btn) =>
      btn.addEventListener("click", (e) => {
        console.log(e.target.closest(".model"));
        e.target.closest(".model").classList.add("hidden");
        document.body.classList.remove("overflow-hidden");
      })
    );
  }

  // if (linksClose) {
  //   linksClose.forEach((btn) =>
  //     btn.addEventListener("click", (e) => {
  //       console.log(e.target.closest(".inner-nav"));
  //       e.target.closest(".inner-nav").classList.add("hidden");
  //       document.body.classList.remove("overflow-hidden");
  //     })
  //   );
  // }
}

function handleScroll() {
  // Define selectors.
  let parent = document.querySelector(".parent");
  let child = parent.querySelector(".child");
  let parentTop = parent.getBoundingClientRect().top || 0;
  let crossChildHeight = 0;
  if (child.clientHeight > parent.getBoundingClientRect().bottom) {
    crossChildHeight =
      parent.getBoundingClientRect().bottom - child.clientHeight;
  }
  if (parseInt(crossChildHeight) < 0) {
    parent.classList.remove("fixed");
    parent.classList.add("absolute");
  } else {
    parent.classList.remove("absolute");
    if (parentTop <= crossChildHeight) {
      parent.classList.add("fixed");
    } else {
      parent.classList.remove("fixed");
    }
  }
}
document.addEventListener("scroll", handleScroll);
