import { gsap } from "gsap";

export default function Menu(els) {
  const innerNav = document.querySelector(".inner-nav");
  let isNavOpen = false;

  els.forEach((el) =>
    el.addEventListener("click", () => {
      document.body.classList.toggle("overflow-hidden");
      console.log("test");
      if (!isNavOpen) {
        isNavOpen = true;
        innerNav.classList.remove("hidden");
        gsap.fromTo(innerNav, { opacity: 0 }, { opacity: 1, duration: 0.2 });
      } else {
        isNavOpen = false;
        gsap.fromTo(
          innerNav,
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.2,
            onComplete: () => innerNav.classList.add("hidden"),
          }
        );
      }
    })
  );
}
