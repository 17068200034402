import Flickity from "flickity";

function Slider(el) {
  el.classList.remove("hidden");
  const options = el.dataset?.options ? JSON.parse(el.dataset.options) : {};
  const indexEl = el.parentElement.querySelector("[data-index]");

  const flk = new Flickity(el, options);

  function prevSlide() {
    flk.previous();
  }

  function nextSlide() {
    flk.next();
  }

  const prev = document.querySelector(options.prevButton);
  if (prev) {
    prev.addEventListener("click", prevSlide);
  }
  const next = document.querySelector(options.nextButton);
  if (next) {
    next.addEventListener("click", nextSlide);
  }

  flk.on("change", (index) => {
    if (indexEl) indexEl.innerText = index + 1;
  });
}

export default Slider;
