import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import eventBus from "./utils/eventBus";
gsap.registerPlugin(ScrollTrigger);

export default function Animations(el) {
  eventBus.on("scrolltrigger:refresh", () => ScrollTrigger.refresh());
}

//Header Block

gsap.from(".header-title", { duration: 1, opacity: 0, delay: 1.2 });
gsap.from(".header-subTitle", { duration: 1, opacity: 0, delay: 1.3 });
gsap.from(".header-description", { duration: 1, opacity: 0, delay: 1.4 });
gsap.from(".header-nav1", { duration: 0.3, opacity: 0, delay: 1.5 });
gsap.from(".header-nav2", { duration: 0.3, opacity: 0, delay: 1.6 });
gsap.from(".header-nav3", { duration: 0.3, opacity: 0, delay: 1.7 });
gsap.from(".header-nav4", { duration: 0.3, opacity: 0, delay: 1.8 });
gsap.from(".header-button", { duration: 1, opacity: 0, delay: 2.4 });
gsap.from(".nav-delay", { duration: 0.5, opacity: 0, delay: 0.7 });

// Intro Heading fade in
gsap.from(".intro-title", {
  stagger: 1,
  duration: 1,
  y: "100%",
  scrollTrigger: {
    trigger: ".intro-title",
    start: "top 100%",
    end: "top 80%",
    scrub: 2,
  },
});

gsap.from(".hello-header1", {
  stagger: 1,
  duration: 2,
  y: "100%",
  scrollTrigger: {
    trigger: ".hello-header1",
    start: "top 70%",
    end: "top 60%",
    scrub: 2,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".hello-header2", {
  stagger: 1,
  duration: 1,
  delay: 2,
  opacity: 0,
  scrollTrigger: {
    trigger: ".hello-header2",
    start: "top 70%",
    end: "top 60%",
    scrub: 2,
    toggleActions: "play pause resume reset",
  },
});

gsap.from(".media-heading", {
  stagger: 1,
  duration: 1,
  y: "100%",
  scrollTrigger: {
    trigger: ".media-heading",
    start: "top 90%",
    end: "top 60%",
    scrub: 2,
  },
});

gsap.from(".intro-image", {
  duration: 1,
  y: "100%",
  scrollTrigger: {
    trigger: ".intro-image2",
    start: "top 100%",
    end: "center",
  },
});

gsap.from(".intro-image2", {
  duration: 1,
  y: "100%",
  scrollTrigger: {
    trigger: ".intro-image2",
    start: "top 100%",
    end: "center",
  },
});

gsap.from(".media-description", {
  duration: 2,
  opacity: 0,
  scrollTrigger: {
    trigger: ".media-description",
    start: "top 70%",
    end: "top 40%",
    scrub: 2,
  },
});

gsap.from(".media-slider__card", {
  stagger: 2,
  duration: 2,
  opacity: 0,
  scrollTrigger: {
    trigger: ".media-slider__card",
    start: "top 80%",
    end: "top 40%",
    scrub: 2,
    toggleActions: "play pause resume reset",
  },
});

gsap.from(".media-view-all", {
  duration: 1,
  opacity: 0,
  scrollTrigger: {
    trigger: ".media-view-all",
    start: "top 100%",
    end: "top 70%",
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".fade-in-sub", {
  duration: 1,
  x: "-100%",
  scrollTrigger: {
    trigger: ".fade-in-sub",
    start: "top 100%",
    end: "top 90%",
  },
});

gsap.from(".imagetext-header", {
  duration: 1,
  y: "100%",
  scrollTrigger: {
    trigger: ".imagetext-header",
    start: "top 100%",
    end: "top 90%",
  },
});

gsap.from(".submit-fade", {
  duration: 1,
  opacity: 0,
  scrollTrigger: {
    trigger: ".submit-fade",
    start: "top 80%",
    end: "top 60%",
  },
});

gsap.from(".imagetext-text", {
  duration: 2,
  opacity: 0,
  scrollTrigger: {
    trigger: ".imagetext-text",
    start: "top 80%",
    end: "top 50%",
    toggleActions: "play complete reverse reset",
  },
});

gsap.from(".imagetext-button1", {
  duration: 2,
  opacity: 0,
  scrollTrigger: {
    trigger: ".imagetext-button1",
    start: "top 100%",
    end: "top 80%",
    toggleActions: "play complete reverse reset",
  },
});
gsap.from(".imagetext-button2", {
  duration: 2,
  opacity: 0,
  scrollTrigger: {
    trigger: ".imagetext-button2",
    start: "top 90%",
    end: "top 70%",
    toggleActions: "play complete reverse reset",
  },
});

gsap.from(".divider-bg", {
  duration: 3,
  x: "-20%",
  scrollTrigger: {
    trigger: ".divider-bg",
    start: "top 130%",
    end: "bottom",
    scrub: 2,
  },
});

gsap.from(".divider-fade", {
  duration: 3,
  opacity: 0,
  scrollTrigger: {
    trigger: ".divider-fade",
    start: "top 70%",
    end: "top 60%",
    scrub: 2,
  },
});

gsap.from(".right-col-fade", {
  stagger: 1,
  duration: 1,
  opacity: 0,
  scrollTrigger: {
    trigger: ".right-col-fade",
    start: "top 60%",
    end: "top 40%",
    scrub: 2,
  },
});

gsap.from(".intro-header", {
  stagger: 1,
  duration: 1,
  y: "100%",
  scrollTrigger: {
    trigger: ".intro-header",
    start: "top 100%",
    end: "top 80%",
    scrub: 2,
  },
});

gsap.from(".intro-abstract", {
  stagger: 1,
  duration: 1,
  opacity: 0,
  scrollTrigger: {
    trigger: ".intro-abstract",
    start: "top 70%",
    end: "top 50%",
    scrub: 2,
  },
});

gsap.from(".intro-Y-text", {
  stagger: 1,
  duration: 1,
  opacity: 0,
  scrollTrigger: {
    trigger: ".intro-Y-text",
    start: "top 60%",
    end: "top 50%",
    scrub: 2,
  },
});

gsap.from(".stats-title", {
  stagger: 1,
  duration: 1,
  y: "100%",
  scrollTrigger: {
    trigger: ".stats-title",
    start: "top 90%",
    end: "top 70%",
    scrub: 2,
  },
});

// intro description block
gsap.from(".intro-load1", {
  opacity: 0,
  stagger: 1,
  duration: 1,
  scrollTrigger: {
    trigger: ".intro-load1",
    start: "top 90%",
    end: "top 70%",
    scrub: 2,
  },
});
gsap.from(".intro-load2", {
  opacity: 0,
  stagger: 1,
  duration: 1,
  scrollTrigger: {
    trigger: ".intro-load2",
    start: "top 95%",
    end: "top 80%",
  },
});
gsap.from(".intro-load3", {
  opacity: 0,
  stagger: 1,
  duration: 0.5,
  scrollTrigger: {
    trigger: ".intro-load3",
    start: "top 90%",
    end: "top 80%",
  },
});

//wave-moving

gsap.from(".wave", {
  stagger: 3,
  duration: 3,
  x: "-5%",
  scrollTrigger: {
    trigger: ".wave",
    start: "top 100%",
    end: "top 0%",
    scrub: 1,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".wave-2", {
  stagger: 3,
  duration: 3,
  x: "5%",
  scrollTrigger: {
    trigger: ".wave-2",
    start: "top 100%",
    end: "top 0%",
    scrub: 1,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".wave-3", {
  stagger: 3,
  duration: 3,
  x: "-5%",
  scrollTrigger: {
    trigger: ".wave-3",
    start: "top 100%",
    end: "top 0%",
    scrub: 1,
    toggleActions: "restart pause resume reset",
  },
});

gsap.from(".wave-4", {
  stagger: 3,
  duration: 3,
  x: "5%",
  scrollTrigger: {
    trigger: ".wave-4",
    start: "top 100%",
    end: "top 0%",
    scrub: 1,
    toggleActions: "restart pause resume reset",
  },
});
