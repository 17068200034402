export default function Stock(el) {
  const tikers = [
    { tick: "BHL.L", curr: "GBP" },
    { tick: "BHLIF", curr: "USD" },
  ];

  tikers.forEach((tiker) => {
    fetch(
      `https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&symbol=${tiker.tick}&interval=5min&apikey=1T5HCOEN7CWTSE42`
    )
      .then((r) => r.json())
      .then((json) => {
        const values = Object.values(json["Time Series (Daily)"]);
        const label = document.createElement("span");
        console.log(json);
        label.innerHTML = `${tiker.tick} ${tiker.curr} ${
          values.pop()["2. high"]
        }`;
        el.appendChild(label);
      });
  });
}
